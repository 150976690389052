import React from 'react';
import styled from 'styled-components';
import Wrapper from '@common/Wrapper';
import '@src/static/fonts/fonts.css';
import logo from '@src/static/logo_white.svg';
import Link from 'gatsby-link';
import { Link as SLink } from 'react-scroll';
const FooterWrapper = styled.footer`
  width: 100vw;
  padding: 10px;
  background: ${p =>
    p.theme.dark ? p.theme.secondaryColor : p.theme.gradient};

  p {
    font-size: 1rem;
    line-height: 35px;
    color: white;
  }

  a {
    color: ${p =>
      p.theme.dark ? p.theme.primaryColor : p.theme.secondaryColor};
    &:hover {
      color: ${p => p.theme.primaryText};
    }
  }

  .seelay-logo {
    font-family: Seelay;
    letter-spacing: 1px;
    font-size: 1.1rem;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <Wrapper>
      <SLink className="logo" smooth offset={-100} to="home">
        <Link className="logo" to="/">
          <img width="35px" src={logo} alt="SEELAY" />
        </Link>
      </SLink>

      <p style={{ float: 'right', fontSize: '0.9rem' }}>
        Copyright © 2024
        <SLink className="logo" smooth offset={-100} to="home">
          <Link className="logo" to="/">
            <span className="seelay-logo" style={{ fontSize: '0.9rem' }}>
              &nbsp;SEELAY&nbsp;
            </span>
          </Link>
        </SLink>
        All Rights Reserved
      </p>
    </Wrapper>
  </FooterWrapper>
);

export default Footer;
